const parallaxStyle = theme => ({
  videoBackground: {
    position: 'fixed',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    zIndex: '-99'
  },
  videoForeground: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    pointerEvents: 'none'
  },
  videoBackgroundIframe: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    pointerEvents: 'none'
  },
  '@media (min-aspect-ratio: 16/9)': {
    videoForeground: {
      height: '300%',
      top: '-100%'
    }
  },
  '@media (max-aspect-ratio: 16/9)': {
    videoForeground: {
      width: '300%',
      left: '-100%'
    }
  },
  '@media all and (max-width: 600px)': {
    vidInfo: {
      width: '50%',
      padding: '0.5rem'
    },
    '.vid-info h1': {
      marginBottom: '0.2rem'
    }
  },
  '@media all and (max-width: 500px)': {
    '.vid-info .acronym': {
      display: 'none'
    }
  },
  parallax: {
    minHeight: "90vh",
    overflow: "hidden",
    position: "relative",
    backgroundPosition: "center top",
    backgroundSize: "cover",
    margin: "0",
    padding: "0",
    border: "0",
    display: "flex",
    alignItems: "center"
  },
  filter: {
    "&:before": {
      background: "rgba(0, 0, 0, 0.5)"
    },
    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''"
    }
  },
  small: {
    height: "380px"
  },
  parallaxResponsive: {
    [theme.breakpoints.down("md")]: {
      minHeight: "660px"
    }
  }
});

export default parallaxStyle;
