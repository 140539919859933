import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/nextjs-material-kit/components/parallaxStyle.js";

import dynamic from 'next/dynamic'
const VideoBackgroundPlayer = dynamic(() => import('components/My/VideoBackgroundPlayer/VideoBackgroundPlayer'), { ssr: false })

const useStyles = makeStyles(styles);

export default function Parallax(props) {
  let windowScrollTop;
  // if (window.innerWidth >= 768) {
  //   windowScrollTop = window.pageYOffset / 3;
  // } else {
  //   windowScrollTop = 0;
  // }
  const [transform, setTransform] = React.useState("translate3d(0,0px,0)");
  React.useEffect(() => {
    if (window.innerWidth >= 768) {
      window.addEventListener("scroll", resetTransform);
    }
    return function cleanup() {
      if (window.innerWidth >= 768) {
        window.removeEventListener("scroll", resetTransform);
      }
    };
  });
  const resetTransform = () => {
    var windowScrollTop = window.pageYOffset / 3;
    setTransform("translate3d(0," + windowScrollTop + "px,0)");  
  };
  const { filter, className, children, style, image, small, responsive, video } = props;
  const classes = useStyles();
  const parallaxClasses = classNames({
    [classes.parallax]: true,
    [classes.filter]: filter,
    [classes.small]: small,
    [classes.parallaxResponsive]: responsive,
    [className]: className !== undefined
  });

  const parallaxClassesVideo = classNames({
    [classes.parallax]: true,
    [classes.small]: small,
    [classes.parallaxResponsive]: responsive,
    [className]: className !== undefined
  });
  // filter: 'blur(4px)',
  // WebkitFilter: 'blur(4px)',

  if(video?.length >= 2) {
    return (
    <div className={parallaxClassesVideo}>
      {children}

      <div className={classes.videoBackground}>
        <div className={classes.videoForeground}>
          <VideoBackgroundPlayer cls={classes.videoBackgroundIframe} src={video} />
        </div>
      </div>
    </div>
  )
}

  return (
    <div
      className={parallaxClasses}
      style={{
        ...style,
        backgroundImage: "url(" + image + ")",
        transform: transform
      }}
    >
      {children}
    </div>
  );
}

Parallax.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.string,
  image: PropTypes.string,
  small: PropTypes.bool,
  // this will add a min-height of 660px on small screens
  responsive: PropTypes.bool
};
